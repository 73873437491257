import { Injectable } from '@angular/core';
import { Router, Event, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  route(url: string): void {
    this.router.navigate( [url] );
  }

  getSnapshot(): string{
    return this.activeRoute.snapshot['_routerState'].url;
  }

}
