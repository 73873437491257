import { bookingServiceProvider } from './../../Shared/app/upgrades/bookingService.wrapper';
import { loginServiceProvider } from './../../Shared/app/upgrades/loginService.wrapper';
import { SharedModule } from './shared/shared.module';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { UpgradeModule } from '@angular/upgrade/static';
import { Router } from '@angular/router';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { MODALS } from './../../Shared/app/forms/shared-forms.module';

import { AppComponent } from './app.component';
import { LogoutComponent } from './logout/logout.component';
import { CacheInterceptor } from '../../Shared/app/forms/interceptors/cache.interceptor';
import { FooterComponent } from './footer/footer.component';
import { AccessibilityStatementComponent } from '../../Shared/app/forms/accessibility-statement/accessibility-statement.component'; 
import { TrustPipe } from '../../Shared/app/forms/pipes/trust.pipe';

import { RaygunErrorHandler } from './../../Shared/app/core/app.raygun.setup';
import { WINDOW_PROVIDERS } from "../../Shared/app/core/services/window.service";
import { RootStateModule } from '../../Shared/app/root-state/root-state.module';

@NgModule({
    imports: [
        RootStateModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        UpgradeModule,
        LocationUpgradeModule.config({ useHash: false }),
        AppRoutingModule,
        SharedModule,
        ModalModule.forRoot()
    ],
    providers: [
        bookingServiceProvider,
        loginServiceProvider,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        BsModalRef,
        { provide: ErrorHandler, useClass: RaygunErrorHandler },
        WINDOW_PROVIDERS
    ],
    declarations: [AppComponent, FooterComponent, AccessibilityStatementComponent, LogoutComponent, TrustPipe]
})
export class AppModule {
    constructor(private router: Router) {}

    ngDoBootstrap() {
        this.router.initialNavigation();
    }
}
