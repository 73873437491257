import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { downgradeItems } from './downgrades';
import { environment } from "../environments/environment";
import 'zone.js';

if (environment.production) {
  enableProdMode();
}

//bootstraps hybrid uppgraded AngularJS and Angular application -- can't be used for testing witch protractor
platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
    downgradeItems();
    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    upgrade.bootstrap(document.documentElement, ['wfApp'], { strictDi: false });   
});
