import { Component, OnInit, Inject } from '@angular/core';


@Component({
    selector: 'wfp-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
}) 
export class LogoutComponent implements OnInit {

    constructor(@Inject('loginService') private loginService:any ) { }
    

    ngOnInit() {
        this.loginService.logout();
    }
}