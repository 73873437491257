<div id="accessibility" class="container">
    <div class="header-logo">
        <div class="wf-logo"></div>
        <div class="region-logo"></div>
    </div>
    <article>
        <h1>Tillgänglighetsredogörelse för Webbformulär</h1>
        <p>
            Region Stockholm är ansvarig utvecklare och förvaltare av Webbformulär. Vi vill att så många som möjligt ska
            kunna använda produkten.
            Det här dokumentet beskriver hur Webbformulär uppfyller lagen om tillgänglighet till digital offentlig
            service,
            eventuella kända tillgänglighetsproblem
            och hur du kan rapportera brister till oss så att vi kan åtgärda dem.
        </p>
        <h2>Hur tillgängligt är Webbformulär?</h2>
        <p>
            Vi är medvetna om att delar av Webbformulär inte är helt tillgänglighetsanpassade. Se avsnitt om innehåll
            som inte är
            tillgängligt.
        </p>
        <h2>Vad kan du göra om du inte kan använda delar av Webbformulär </h2>
        <p>
            Om du som invånare inte kan genomföra formuläret kan du alltid kontakta den vårdgivare som har bokat
            Webbformuläret åt dig. Vårdgivaren har möjlighet att, tillsammans med dig, hjälpa dig att fylla i
            formuläret.
        </p>
        <h2>Rapportera brister i Webbformulärs tillgänglighet </h2>
        <p>
            Vi strävar hela tiden efter att förbättra Webbformulärs tillgänglighet. Om du upptäcker problem som inte är
            beskrivna på den här sidan,
            eller om du anser att vi inte uppfyller lagens krav, meddela oss så att vi får veta att problemet finns.
        </p>
        <div>
            Du kan nå oss på: <br />
            <a class="linkDarkBlue" href="mailto:fo.vardprocess.rst@regionstockholm.se">fo.vardprocess@regionstockholm.se <i class="fas fa-external-link-alt"></i></a>
        </div>
        <h2>Tillsyn</h2>
        <p>
            Myndigheten för digital förvaltning har ansvaret för tillsyn för lagen om tillgänglighet till digital
            offentlig
            service.
            Om du inte är nöjd med hur vi hanterar dina synpunkter kan du kontakta Myndigheten för digital
            förvaltning och
            påtala det.
        </p>
        <div>
            Myndigheten kan nås på följande länk:<br />
            <a class="linkDarkBlue" href="https://www.digg.se/tdosanmalan" target="_blank">https://www.digg.se/tdosanmalan <i class="fas fa-external-link-alt"></i></a>
        </div>

        <h2>Hur arbetar Region Stockholm med tillgänglighetsanpassningen </h2>
        <p>
            Region Stockholm arbetar löpande med att öka tillgängligheten i Webbformulär. Planen är att till den 23
            september 2020 ska de mest använda invånarformulären vara anpassade.
        </p>
        <div>
            Arbetet genomförs enligt nedan:
            <ul>
                <li>
                    Gemensamma komponenter prioriteras och anpassas
                </li>
                <li>
                    Nya formulär ska vara anpassade när de släpps (från och med den 1 april 2020)
                    <ul>
                        <li>
                            Enligt implementerade arbetssätt och rutiner
                        </li>
                        <li>
                            Enligt checklistor för nyutveckling av formulär
                        </li>
                    </ul>
                </li>
                <li>
                    Befintliga formulär anpassas i tur och ordning (de mest använda formulären anpassas först)
                </li>
            </ul>
        </div>
        <p>
            Formulär består av gemensamma komponenter men även unika komponenter. Det innebär att formulären gradvis
            kommer att tillgänglighetsanpassas i samband med att de gemensamma komponenterna anpassas.
        </p>
        <p>
            Ett formulär godkänns inte som tillgänglighetsanpassat, i den här rapporten, innan formuläret är
            kontrollerat i sin helhet.
        </p>

        <h2>Granskningsmetod</h2>
        <p>
            Varje formulär gås igenom och testas enligt checklistor (från WCAG 2.1). Beteenden och komponenter som inte
            uppfyller tillgänglighetsdirektiven anpassas. Formuläret genomgår till sist en slutgiltig granskning och
            godkänns som tillgänglighetsanpassad när alla krav enligt WCAG 2.1 är uppfyllda.
        </p>
        <p>Formulären testas i Chrome tillsammans med NVDA (NonVisual Desktop Access) som uppläsningsverktyg. </p>
        <!--  -->

        <h2>Godkända formulär</h2>
        <p>Följande formulär är godkända tillgänglighetsmässigt i Webbformulär</p>

        <ul class="forms">
            <li *ngFor="let form of approvedForms">{{form}}</li>
        </ul>

        <h2>Granskade formulär </h2>
        <p>
            Formulären i följande punktlista har testats enligt tillgänglighetskraven.
        </p>
        <p>
            Tillgänglighetsanpassningar är påbörjade i formulären alternativt planeras att påbörjas i det närmaste.
            Vissa av formulären har skydd i form av Copyright, vilket medför att textändringar kräver godkännande av
            annan instans.
        </p>
        <ul class="forms">
            <li *ngFor="let form of testedForms">{{form}}</li>
        </ul>
        <h2>Teknisk information om Webbformulärs tillgänglighet</h2>
        <p>
            För närvarande uppfyller Webbformulär delvis lagkravet för digital tillgänglighet inom offentlig service. Vi
            redovisar bristerna på ett övergripande plan.
        </p>
        <p>
            Vi är medvetna om att vi inte uppfyller alla kriterier enligt WCAG 2.1 i Webbformulär, inom följande
            områden:
        </p>
        <ul>
            <li>
                Ge användarna möjlighet att justera tidsbegränsningar (direktiv 131)
                <ul>
                    <li>
                        Då den information som invånare lämnar i Webbformulär är av känslig karaktär har Region
                        Stockholm
                        beslutat att inte erbjuda användare möjlighet att justera tidsbegränsningar.
                    </li>
                    <li>
                        Detta innebär att om en invånare, inloggad i Webbformulär, är inaktiv i mer än 10 minuter, så
                        loggas
                        hen automatiskt ut.
                        Om invånaren har börjat svara på formuläret, så sparas dessa svar i ett utkast. Invånaren kan
                        när
                        som helst logga in igen och öppna upp utkastet och fortsätta besvara formuläret.
                    </li>
                </ul>
            </li>
            <!-- <li>
                Ge användarna möjlighet att ändra och bekräfta viktiga val (direktiv 150)
                <ul>
                    <li>
                        I formulären MADRS-S, KABOSS-S, PDSS-SR och Y-BOCS-SR är det inte möjligt att gå tillbaka och
                        ändra
                        sina svar så som det är möjligt i alla andra formulär.
                    </li>
                    <li>
                        Anledningen är att invånaren inte ska ”tänka så mycket” utan bara svara på formuläret och därför
                        är
                        det inte önskvärt att invånaren ska kunna ändra sina svar.
                    </li>
                </ul>
            </li> -->
        </ul>
        <!-- <p>
            Formulären i följande punktlista har inte genomgått testning av tillgänglighetskraven. Det innebär att det
            för
            vissa formulär kan finnas punkter i direktivet som inte uppfylls för respektive formulär, medan andra
            uppfyller
            alla krav.<br />
            Arbetet med att tillgänglighetsanpassa formulär sker fortlöpande. Formulären presenteras i bokstavsordning.
        </p>
        <ul class="forms">
            <li *ngFor="let form of notTestedForms">{{form}}</li>
        </ul> -->
        <p>
            Formulären i följande punktlista kommer inte genomgå anpassningar för tillgänglighet. Detta på grund av att de inte använts under den senaste tiden.
            
        </p>
        <ul class="forms">
            <li *ngFor="let form of notTestedForms">{{form}}</li>
        </ul>

        <h2>Uppdatering av redogörelsen</h2>
        <p>
            Denna redogörelse uppdaterades 2022-10-12.
        </p>
    </article>
</div>
