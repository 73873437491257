import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponentWrapper } from '../upgrades/components/header.component.wrapper';


@NgModule({
  declarations: [HeaderComponentWrapper],
  imports: [CommonModule],
  exports: [HeaderComponentWrapper, CommonModule]
})
export class SharedModule { }
