import { Component, Inject } from '@angular/core';
import { WINDOW } from "../../../Shared/app/core/services/window.service";

@Component({
    templateUrl: 'footer.component.html',
    selector: 'wfp-footer',
    styleUrls: ['footer.component.scss']
})
export class FooterComponent {

    constructor(@Inject(WINDOW) private window: Window) { }

      
    openStatementWindow(url:string):void{
        this.window.open(url,"_blank");
    }
}
